import { Link, Outlet, useLocation, useParams } from '@remix-run/react';
import { Suspense, useLayoutEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { Loading } from '../../components/Loading';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { LeaderboardStoreProvider, useLeaderboardStore } from './Context';

function LeaderboardContent(): JSX.Element {
  const state = useLeaderboardStore();

  if (state.isLoadingRound && !state.round) {
    return <Loading containerClassName='h-full' />;
  }

  if (state.roundError || !state.round) {
    return (
      <div className='mt-20 text-sms text-white text-center'>
        Oops – we canʼt find the page youʼre looking for. You can hop back over
        to the{' '}
        <Link to='/home' className='text-primary'>
          home page
        </Link>{' '}
        if youʼd like.
      </div>
    );
  }

  return (
    <Suspense fallback={<Loading containerClassName='h-full' />}>
      <Outlet />
    </Suspense>
  );
}

export function LeaderboardContainer(): JSX.Element {
  const { roundId } = useParams<'roundId'>();
  const content = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const prevLocation = usePrevious(location);

  // since the content element has a scroll, we need to reset it when we navigate...
  useLayoutEffect(() => {
    if (location !== prevLocation) content.current?.scrollTo(0, 0);
  }, [prevLocation, location]);

  if (!roundId) {
    return <></>;
  }

  return (
    <PublicHomeLayout>
      <LeaderboardStoreProvider roundId={roundId}>
        <div
          ref={content}
          className='flex-1 overflow-y-auto scrollbar bg-leaderboard bg-w-full bg-no-repeat bg-top bg-local'
        >
          <LeaderboardContent />
        </div>
      </LeaderboardStoreProvider>
    </PublicHomeLayout>
  );
}
