import { Link } from '@remix-run/react';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import { DoubleRightArrow } from '../../components/icons/Arrows';
import { CrownIcon } from '../../components/icons/CrownIcon';
import { GlobeIcon } from '../../components/icons/GlobeIcon';
import { ScoreboardIcon } from '../../components/icons/ScoreboardIcon';
import { type PaletteType, TopRankedTeam } from '../../components/Leaderboard';
import { Loading } from '../../components/Loading';
import { useUser } from '../../components/UserContext';
import { type LeaderboardTeam } from '../../types';
import { ordinal } from '../../utils/common';
import { type PaginatedLeaderboard, useLeaderboardStore } from './Context';
import {
  getGlobalCompanyLeaderboardTitle,
  getGlobalLeaderboardTitle,
  getRoundLeaderboardTitle,
} from './utils';

function TeamsByRank(props: {
  teams: LeaderboardTeam[];
  myUserId: string;
  palette: PaletteType;
  includeOrganizationName?: boolean;
  limit?: number;
  topRankedTeamsLimit?: number;
  detailHref?: string;
  emptyStateMessage: string;
}): JSX.Element {
  const topRankedTeamsLimit = props.topRankedTeamsLimit ?? 3;
  const items = props.limit ? props.teams.slice(0, props.limit) : props.teams;

  let currentRank = 0;
  let currentRankScore = -Infinity;

  const teams = items.map((team, index) => {
    const teamName = team.teamName ? team.teamName : '[Unknown Team Name]';
    const score = team.score ?? 0;
    const players = (team.players ?? [])
      .map((player) => player.displayName)
      .filter((player) => player.length > 0)
      .join(', ');
    const isMyTeam =
      (team.players ?? []).findIndex((p) => p.uid === props.myUserId) > -1;
    const textColor = isMyTeam ? 'text-tertiary' : 'text-white';

    // If it's a company leaderboard, there is no pairingId
    const key = team.pairingId || team.orgId;

    if (index < topRankedTeamsLimit) {
      if (score !== currentRankScore) {
        currentRank = index + 1;
        currentRankScore = score;
      }
      return (
        <TopRankedTeam
          key={key}
          palette={props.palette}
          textColor={textColor}
          rank={currentRank}
          teamName={teamName}
          players={players}
          score={score}
          organizationName={
            props.includeOrganizationName ? team.orgName : undefined
          }
        />
      );
    }

    return (
      <div key={key} className={`flex items-center gap-4 min-w-0 ${textColor}`}>
        <div className='flex-grow min-w-0'>
          {props.includeOrganizationName && (
            <div className='font-medium text-xs truncate'>{team.orgName}</div>
          )}
          <div className='font-bold text-base truncate'>{teamName}</div>
          <div className='font-medium text-xs truncate'>{players}</div>
        </div>
        <div className='flex-shrink-0 text-sms'>{score}</div>
      </div>
    );
  });

  if (teams.length === 0) {
    return (
      <div className='w-full h-full p-9 text-white text-center'>
        {props.emptyStateMessage}
      </div>
    );
  }

  return (
    <div className='flex flex-col'>
      <div className='w-full grid grid-cols-3 grid-flow-row gap-y-10 gap-x-8'>
        {teams}
      </div>

      {props.detailHref && props.limit && props.teams.length >= props.limit && (
        <Link
          to={props.detailHref}
          className='mt-6 self-end flex items-center gap-3 text-white font-medium tracking-wider leading-none'
        >
          Show All
          <DoubleRightArrow />
        </Link>
      )}
    </div>
  );
}

function LeaderboardSection(props: {
  detailed?: boolean;
  limit?: number;
  topRankedTeamsLimit?: number;
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  titleAccessory?: React.ReactNode;
  leaderboard: PaginatedLeaderboard;
  palette: PaletteType;
  detailHref?: string;
  includeOrganizationName?: boolean;
  emptyStateMessage: {
    active: string;
    inactive: string;
  };
}): JSX.Element {
  const user = useUser();
  const state = useLeaderboardStore();

  if (!state.round) return <></>;

  let body = null;
  if (props.leaderboard.isLoading && !props.leaderboard.items) {
    body = (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading />
      </div>
    );
  } else if (props.leaderboard.error) {
    body = (
      <div className='w-full h-full flex items-center justify-center'>
        <div className='text-white'>
          Failed to load the leaderboard. Refresh to try again.
        </div>
      </div>
    );
  } else {
    const isRoundOver = new Date(state.round.endedAt) <= new Date();
    body = (
      <TeamsByRank
        teams={props.leaderboard.items ?? []}
        myUserId={user.id}
        emptyStateMessage={
          isRoundOver
            ? props.emptyStateMessage.inactive
            : props.emptyStateMessage.active
        }
        palette={props.palette}
        limit={props.limit}
        topRankedTeamsLimit={props.topRankedTeamsLimit}
        detailHref={props.detailHref}
        includeOrganizationName={props.includeOrganizationName}
      />
    );
  }

  if (props.detailed) {
    return (
      <div>
        {body}
        {props.leaderboard.isLoading ? (
          <Loading containerClassName='mt-4' />
        ) : !props.leaderboard.error && props.leaderboard.hasMore ? (
          <Waypoint
            onEnter={() => props.leaderboard.loadMore()}
            fireOnRapidScroll
          >
            <div>&nbsp;</div>
          </Waypoint>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div className='flex mb-5 items-center justify-between gap-10'>
        <div className='truncate'>
          <div className='flex items-center gap-2'>
            {props.icon}
            <div className='text-white font-medium text-xl truncate'>
              {props.title}
            </div>
          </div>
          {props.subtitle && (
            <div className='text-white text-sms ml-6.5 mt-1 truncate'>
              {props.subtitle}
            </div>
          )}
        </div>
        {props.titleAccessory}
      </div>

      <div className='w-full bg-lp-black-003 rounded-xl p-8'>{body}</div>
    </div>
  );
}

export function OrganizationLeaderboardSection(props: {
  detailed?: boolean;
  limit?: number;
}): JSX.Element {
  const state = useLeaderboardStore();
  const title = getRoundLeaderboardTitle(state.round);

  const yourCompany = state.round?.organization?.name ?? 'your company';
  const emptyStateMessage = {
    active: `No one from ${yourCompany} has played yet! Which pair will play first?`,
    inactive: `No one from ${yourCompany} played in this round.`,
  };

  return (
    <LeaderboardSection
      detailed={props.detailed}
      limit={props.limit}
      icon={
        <ScoreboardIcon className='w-4.5 h-4.5 text-blue-005 fill-current flex-shrink-0' />
      }
      title={title}
      leaderboard={state.orgLeaderboard}
      palette='blue'
      detailHref='round'
      emptyStateMessage={emptyStateMessage}
    />
  );
}

const GENERIC_EMPTY_STATE_MESSAGE = {
  inactive: 'No Luna Park Pairs games were played for this round.',
  active:
    'No Luna Park Pairs games have been played for this round yet. Scores will show up here as teams start playing!',
};

export function GlobalLeaderboardSection(props: {
  detailed?: boolean;
  limit?: number;
}): JSX.Element {
  const state = useLeaderboardStore();
  const title = getGlobalLeaderboardTitle(state.round?.mainGamePack);

  return (
    <LeaderboardSection
      detailed={props.detailed}
      limit={props.limit}
      icon={
        <GlobeIcon className='w-4.5 h-4.5 text-pink-001 fill-current flex-shrink-0' />
      }
      title={title}
      subtitle='Ranked using total points scored per pair'
      leaderboard={state.globalLeaderboard}
      palette='pink'
      detailHref='global'
      emptyStateMessage={GENERIC_EMPTY_STATE_MESSAGE}
      includeOrganizationName
    />
  );
}

function GlobalCompanyRank(props: {
  visibleAfterRank: number;
}): JSX.Element | null {
  const state = useLeaderboardStore();

  // hide the widget if there is no rank or if it is below the given limit
  if (
    !state.globalCompanyRank ||
    state.globalCompanyRank <= props.visibleAfterRank
  ) {
    return null;
  }

  return (
    <div className='bg-gradient-to-bl from-pairing-start to-pairing-end rounded-xl p-px h-12 w-1/3'>
      <div className='rounded-xl bg-black h-full flex items-center'>
        <div className='flex-none h-full rounded-l-xl leaderboard-angled-box bg-lp-gray-001'>
          <div
            className={`h-full w-21 flex items-center justify-center text-tertiary font-bold`}
          >
            {ordinal(state.globalCompanyRank)}
          </div>
        </div>
        <div className='ml-2.5 mr-5 flex-grow text-white font-bold flex items-center justify-between'>
          <div>{state.round?.organization?.name ?? 'Your Company'}</div>
          <div>{state.round?.orgScore ?? 0}</div>
        </div>
      </div>
    </div>
  );
}

const COMPANY_LEADERBOARD_LIMIT = 9;

export function GlobalCompanyLeaderboardSection(): JSX.Element {
  const state = useLeaderboardStore();
  const title = getGlobalCompanyLeaderboardTitle(state.round?.mainGamePack);

  return (
    <LeaderboardSection
      limit={COMPANY_LEADERBOARD_LIMIT}
      topRankedTeamsLimit={COMPANY_LEADERBOARD_LIMIT}
      icon={
        <CrownIcon className='w-4.5 h-4.5 text-yellow-001 fill-current flex-shrink-0' />
      }
      title={title}
      subtitle='Ranked by averaging the scores of the top 5 pairs at each company'
      titleAccessory={
        <GlobalCompanyRank visibleAfterRank={COMPANY_LEADERBOARD_LIMIT} />
      }
      leaderboard={state.globalCompanyLeaderboard}
      palette='gold'
      emptyStateMessage={GENERIC_EMPTY_STATE_MESSAGE}
    />
  );
}
