import pluralize from 'pluralize';
import React from 'react';

import { Loading } from '../../components/Loading';
import { useUser } from '../../components/UserContext';
import { OrganizerUtils, type Pairing, PairingUtils } from '../../types';
import { ordinal } from '../../utils/common';
import { useLeaderboardStore } from './Context';

function MyPairingContainer(props: {
  children: React.ReactNode;
  footerAccessory?: React.ReactNode;
}): JSX.Element {
  return (
    <div className='flex flex-col gap-2.5 w-full max-w-165'>
      <div className='text-white font-bold text-sm'>Your Teamʼs High Score</div>

      <div className='bg-gradient-to-bl from-pairing-start to-pairing-end rounded-xl p-0.5 h-25'>
        <div className='rounded-xl bg-black h-full flex items-center'>
          {props.children}
        </div>
      </div>

      {props.footerAccessory}
    </div>
  );
}

function MyPairingInner(props: {
  pairing: Pairing | undefined;
  myUserId: string;
}): JSX.Element {
  let rank = '--';
  let teamName = '[Not yet played]';
  let bestScore = '--';
  let partners = 'Partner: None';
  let lastPlayed: Date | null = null;

  if (!props.pairing) {
    teamName = '[Not a participant]';
  } else {
    const { mainSession: session, players, round } = props.pairing;
    const partnerNames = (players ?? [])
      .filter((p) => p.organizer?.uid !== props.myUserId)
      .map((p) => OrganizerUtils.GetDisplayName(p.organizer));

    partners = `${pluralize(
      'Partner',
      partnerNames.length
    )}: ${partnerNames.join(', ')}`;

    if (session !== null) {
      teamName = props.pairing.teamName ?? '';
      bestScore = `${props.pairing.score ?? 0}`;
      lastPlayed = PairingUtils.GetLastPlayed(props.pairing);
      if (props.pairing.rank) {
        rank = ordinal(props.pairing.rank);
      }
    } else {
      if (round && new Date(round.endedAt) < new Date()) {
        teamName = '[Did not play]';
      }
    }
  }

  return (
    <MyPairingContainer
      footerAccessory={
        lastPlayed && (
          <div className='text-white text-sms text-right'>
            Last played on {lastPlayed.toLocaleString()}
          </div>
        )
      }
    >
      <div className='w-31.5 h-full bg-lp-black-003 leaderboard-angled-box pl-3 pr-4 flex-none'>
        <div className='h-full flex items-center justify-center text-tertiary text-[32px] italic font-bold'>
          {rank}
        </div>
      </div>

      <div className='flex-grow h-full p-4.5 flex items-center gap-8.5 min-w-0'>
        <div className='flex flex-col gap-1.5 flex-grow flex-shrink truncate'>
          <div
            className={`
              font-medium text-2xl leading-tight truncate
              ${lastPlayed !== null ? 'text-white' : 'text-red-002'}
            `}
          >
            {teamName}
          </div>
          <div className='text-white font-bold text-base leading-tight tracking-wide truncate'>
            {partners}
          </div>
        </div>

        <div className='flex flex-col items-center mr-4 flex-none text-white'>
          <div className='font-bold text-4xl'>{bestScore}</div>
          <div className='font-normal uppercase text-sms'>Team Points</div>
        </div>
      </div>
    </MyPairingContainer>
  );
}

export function MyPairing(): JSX.Element {
  const user = useUser();
  const state = useLeaderboardStore();

  if (state.isLoadingMyPairing) {
    return (
      <MyPairingContainer>
        <div className='w-full h-full flex items-center justify-center'>
          <Loading />
        </div>
      </MyPairingContainer>
    );
  }

  if (state.myPairingError) {
    return (
      <MyPairingContainer>
        <div className='w-full h-full flex items-center justify-center'>
          <div className='text-red-002 text-center text-sm'>
            Failed to fetch your pairing data. <br />
            Reload the page to retry.
          </div>
        </div>
      </MyPairingContainer>
    );
  }

  return <MyPairingInner myUserId={user.id} pairing={state.myPairing} />;
}
