const Palettes = {
  blue: {
    bgColor: 'bg-lp-blue-002',
    rankColor: 'text-white',
  },
  pink: {
    bgColor: 'bg-lp-pink-001',
    rankColor: 'text-white',
  },
  gold: {
    bgColor: 'bg-tertiary',
    rankColor: 'text-black',
  },
  gray: {
    bgColor: 'bg-secondary',
    rankColor: 'text-white',
  },
} as const;

export type PaletteType = keyof typeof Palettes;

export function TopRankedTeam(props: {
  palette: PaletteType;
  textColor: string;
  teamName: string;
  players?: string;
  score: number;
  rank: number;
  organizationName?: string;
}): JSX.Element {
  const { bgColor, rankColor } = Palettes[props.palette];
  return (
    <div className='w-full flex items-center h-16 gap-0.5'>
      <div className='h-full bg-lp-black-003 rounded-l-xl flex items-center min-w-0 flex-grow'>
        <div
          className={`flex-none h-full rounded-l-xl leaderboard-angled-box ${bgColor}`}
        >
          <div
            className={`h-full w-13.5 flex items-center justify-center ${rankColor} text-xl font-bold`}
          >
            {props.rank}
          </div>
        </div>
        <div
          className={`h-full flex-grow flex-shrink px-2.5 flex flex-col justify-center truncate ${props.textColor}`}
        >
          {props.organizationName && (
            <div className='font-medium text-xs leading-tight truncate'>
              {props.organizationName}
            </div>
          )}
          <div className='font-bold leading-tight truncate'>
            {props.teamName}
          </div>
          {props.players && (
            <div className='font-medium text-xs leading-tight truncate'>
              {props.players}
            </div>
          )}
        </div>
      </div>

      <div
        className={`h-full rounded-r-xl ${props.textColor} flex items-center justify-center bg-lp-black-003 px-4 font-bold italic text-lg`}
      >
        {props.score}
      </div>
    </div>
  );
}
