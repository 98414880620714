import { ChannelUtils } from '../../components/Channel';
import { type PairingRound } from '../../types';
import { type GamePack } from '../../types/game';

export function getRoundLeaderboardTitle(round?: PairingRound): string {
  const title = ['Leaderboard'];

  const orgName = round?.organization?.name;
  const channelName = ChannelUtils.ChannelName(round?.channel);
  if (channelName) {
    title.unshift(channelName);
  }
  // note: in some cases channel name is equal to org name. in that case, just use the tournament name.
  if (orgName && (!channelName || channelName !== orgName)) {
    title.unshift(orgName);
  }
  return title.join(' ');
}

export function getGlobalLeaderboardTitle(
  gamePack: GamePack | undefined | null
): string {
  const title = 'Global Pairs Leaderboard';
  return gamePack ? `${title} – ${gamePack.name}` : title;
}

export function getGlobalCompanyLeaderboardTitle(
  gamePack: GamePack | undefined | null
): string {
  const title = 'Global Company Leaderboard';
  return gamePack ? `${title} – ${gamePack.name}` : title;
}
