import axios from 'axios';
import React, { useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useListLoader } from '../../hooks/useListLoader';
import {
  apiService,
  DummyPaginator,
  type LeaderboardTeamsResponse,
  MyPairingsQueryType,
  type Paginator,
} from '../../services/api-service';
import {
  type LeaderboardTeam,
  type Pairing,
  type PairingRound,
} from '../../types';
import { ValtioUtils } from '../../utils/valtio';

export type PaginatedLeaderboard = {
  items?: LeaderboardTeam[];
  isLoading: boolean;
  error?: unknown;
  hasMore?: boolean;
  loadMore(): void;
};

type LeaderboardStore = {
  round?: PairingRound;
  myPairing?: Pairing;
  globalCompanyRank?: number;
  orgLeaderboard: PaginatedLeaderboard;
  globalLeaderboard: PaginatedLeaderboard;
  globalCompanyLeaderboard: PaginatedLeaderboard;
  globalLeaderboardEnabled?: boolean;
  isLoadingRound: boolean;
  isLoadingMyPairing: boolean;
  roundError?: unknown;
  myPairingError?: unknown;
};

const initial: LeaderboardStore = {
  isLoadingRound: true,
  isLoadingMyPairing: true,
  orgLeaderboard: {
    isLoading: true,
    loadMore() {
      return;
    },
  },
  globalLeaderboard: {
    isLoading: true,
    loadMore() {
      return;
    },
  },
  globalCompanyLeaderboard: {
    isLoading: true,
    loadMore() {
      return;
    },
  },
};

const Context = React.createContext<LeaderboardStore | undefined>(undefined);

async function loadLeaderboard(
  store: LeaderboardStore,
  roundId: string,
  setGlobalRoundId: (roundId: string) => void
) {
  store.isLoadingRound = true;
  try {
    const resp = await apiService.pairing.getLeaderboard(roundId);
    store.round = resp.data.round;
    store.globalCompanyRank = resp.data.globalCompanyRank;
    setGlobalRoundId(resp.data.round.globalRoundId);
  } catch (e) {
    store.roundError = e;
  } finally {
    store.isLoadingRound = false;
  }
}

async function loadPairing(store: LeaderboardStore, roundId: string) {
  store.isLoadingMyPairing = true;
  try {
    const resp = await apiService.pairing.getMyPairings({
      type: MyPairingsQueryType.Round,
      roundId,
      withRank: true,
    });
    store.myPairing = resp?.data.pairings[0];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        store.myPairing = undefined;
      }
    }
    store.myPairingError = error;
  } finally {
    store.isLoadingMyPairing = false;
  }
}

function usePaginatedLeaderboard(
  proxy: PaginatedLeaderboard,
  paginator: Paginator<LeaderboardTeamsResponse, LeaderboardTeam>
) {
  const list = useListLoader(paginator);
  useLayoutEffect(() => {
    ValtioUtils.update(proxy, {
      items: list.items,
      hasMore: paginator.hasMore(),
      error: list.error ?? undefined,
      isLoading: list.state.isRunning,
      loadMore: list.handleLoadMore,
    });
  }, [proxy, paginator, list]);
}

export function LeaderboardStoreProvider(props: {
  roundId: string;
  children: React.ReactNode;
}): JSX.Element {
  const globalLeaderboardEnabled = useFeatureQueryParam(
    'global-pairs-leaderboard'
  );
  const ref = useRef<LeaderboardStore>(
    proxy({ ...initial, globalLeaderboardEnabled })
  );
  const [globalRoundId, setGlobalRoundId] = React.useState<
    string | undefined
  >();

  useLayoutEffect(() => {
    ValtioUtils.update(ref.current, { ...initial });
    loadLeaderboard(ref.current, props.roundId, setGlobalRoundId);
    loadPairing(ref.current, props.roundId);
  }, [props.roundId]);

  usePaginatedLeaderboard(
    ref.current.orgLeaderboard,
    useMemo(
      () => apiService.pairing.getOrgLeaderboardTeams(props.roundId),
      [props.roundId]
    )
  );

  usePaginatedLeaderboard(
    ref.current.globalLeaderboard,
    useMemo(() => {
      return globalLeaderboardEnabled && globalRoundId
        ? apiService.pairing.getGlobalLeaderboardTeams(globalRoundId)
        : new DummyPaginator();
    }, [globalLeaderboardEnabled, globalRoundId])
  );

  usePaginatedLeaderboard(
    ref.current.globalCompanyLeaderboard,
    useMemo(() => {
      return globalLeaderboardEnabled && globalRoundId
        ? apiService.pairing.getGlobalCompanyLeaderboardTeams(globalRoundId)
        : new DummyPaginator();
    }, [globalLeaderboardEnabled, globalRoundId])
  );

  return (
    <Context.Provider value={ref.current}>{props.children}</Context.Provider>
  );
}

function useLeaderboardStoreContext(): LeaderboardStore {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('LeaderboardProvider is not in the tree!');
  }
  return ctx;
}

export function useLeaderboardStore(): LeaderboardStore {
  const state = useLeaderboardStoreContext();
  return useSnapshot(state) as typeof state;
}
