import { Link } from '@remix-run/react';

import { assertExhaustive } from '../../utils/common';
import { useLeaderboardStore } from './Context';
import {
  GlobalLeaderboardSection,
  OrganizationLeaderboardSection,
} from './LeaderboardSection';
import { getGlobalLeaderboardTitle, getRoundLeaderboardTitle } from './utils';

export function LeaderboardDetail(props: {
  type: 'round' | 'global';
}): JSX.Element {
  const state = useLeaderboardStore();

  if (!state.round) return <></>;

  let title = '';
  let body = null;
  switch (props.type) {
    case 'round':
      title = getRoundLeaderboardTitle(state.round);
      body = <OrganizationLeaderboardSection detailed />;
      break;
    case 'global':
      title = getGlobalLeaderboardTitle(state.round.mainGamePack);
      body = <GlobalLeaderboardSection detailed />;
      break;
    default:
      assertExhaustive(props.type);
  }

  return (
    <div className='w-full my-10'>
      <section className='mb-8 px-16'>
        <div className='flex items-center text-2xl'>
          <Link
            to={`/leaderboards/${state.round.id}`}
            className='text-secondary hover:text-white hover:underline'
          >
            Pair Round Detail
          </Link>
          <div className='text-secondary mx-2'>/</div>

          <div className='text-white font-bold'>{title}</div>
        </div>
      </section>

      <section className='mt-7.5 px-16'>{body}</section>
    </div>
  );
}
