import { formatDistanceToNowStrict } from 'date-fns';
import pluralize from 'pluralize';

import { GamePackCover } from '../../components/Game/Utilities';
import { type GamePack } from '../../types/game';

export function GamePackDetails(props: {
  gamePack: GamePack | null;
  playersCount: number;
  channelName: string | null | undefined;
  startDate: string;
  endDate: string;
}): JSX.Element {
  const playersCount = [
    props.playersCount,
    pluralize('participant', props.playersCount),
  ];
  if (props.channelName) {
    playersCount.push(`in ${props.channelName}`);
  }

  let timeDetail = '';
  const start = new Date(props.startDate);
  const end = new Date(props.endDate);
  const now = new Date();

  if (end <= now) {
    timeDetail = `Ended on ${end.toLocaleDateString()}`;
  } else if (now < start) {
    timeDetail = `Starts on ${start.toLocaleDateString()}`;
  } else {
    const timeRemaining = formatDistanceToNowStrict(end);
    timeDetail = `${timeRemaining} remaining`;
  }

  return (
    <div className='flex items-center gap-6'>
      <div className='h-31 w-55 flex-none'>
        <GamePackCover pack={props.gamePack} />
      </div>

      <div className='flex flex-col flex-grow text-white truncate'>
        <div className='font-medium text-2xl leading-tigh truncate'>
          {props.gamePack?.name ?? 'Unknown Gamepack'}
        </div>
        <div
          className={`${
            end > now ? 'text-tertiary' : 'text-secondary'
          } font-normal text-xl leading-tight capitalize truncate`}
        >
          {timeDetail}
        </div>
        <div className='text-sms text-secondary mt-2 truncate'>
          {playersCount.join(' ')}
        </div>
      </div>
    </div>
  );
}
